/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		Aura Residential
		Date:		14th June 2017
		Author:		Edge Marketing Solutions

===========================================================================*/
/* [Global Variables] - http://sass-lang.com/guide */
/*=========================================================================*/
/* [Global Template Styles] */
body {
  color: #808184;
  font-family: "Open Sans", sans-serif;
}

.row-spaced {
  margin-top: 50px;
}

h1 {
  margin: 0 0 30px 0;
  color: #4a6693;
  font-family: "Cinzel", serif;
  font-size: 48px;
  text-transform: uppercase;
}

h2 {
  margin: 30px 0;
  color: #4a6693;
  font-family: "Cinzel", serif;
  font-size: 38px;
}

h3 {
  margin: 30px 0;
  color: #4a6693;
  font-family: "Cinzel", serif;
  font-size: 28px;
}

h4 {
  margin: 30px 0;
  color: #4a6693;
  font-family: "Cinzel", serif;
  font-size: 22px;
}

h5 {
  margin: 30px 0;
  color: #808184;
  font-family: "Cinzel", serif;
  font-size: 22px;
}

h6 {
  margin: 30px 0 5px 0;
  color: #4a6693;
  font-family: "Cinzel", serif;
  font-size: 22px;
}

p.lead {
  font-family: "Cinzel", serif;
  font-size: 26px;
}

a {
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  color: #4a6693;
}

img {
  max-width: 100%;
}

img[data-size-ratio^="0"] {
  background: #ffffff url(../images/loading.gif) no-repeat 50% 50%;
}

img.svg-responsive {
  width: 100% \9;
}

.lightbox-gallery .gallery-image {
  cursor: zoom-in;
}

.grecaptcha-badge {
  float: right;
}

.g-recaptcha.submit-btn {
  display: inline;
  width: auto;
}

/*OPTIONAL FULL WIDTH NAVIGATION - Add nav-justified to <ul class="nav navbar-nav">*/
.navbar-nav.nav-justified > li {
  float: none !important;
}

/* Generated by Glyphter (http://www.glyphter.com) on Tue Sep 06 2016*/
@font-face {
  font-family: 'social icons';
  src: url("../fonts/social-icons.eot");
  src: url("../fonts/social-icons.eot?#iefix") format("embedded-opentype"), url("../fonts/social-icons.woff") format("woff"), url("../fonts/social-icons.ttf") format("truetype"), url("../fonts/social-icons.svg#social-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="social-icon-"]::before, [class*=" social-icon-"]::before {
  display: inline-block;
  font-family: 'social icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.social-icon-square-facebook::before {
  content: '\0041';
}

.social-icon-square-google-plus::before {
  content: '\0042';
}

.social-icon-square-linkedin::before {
  content: '\0043';
}

.social-icon-square-pinterest::before {
  content: '\0044';
}

.social-icon-square-twitter::before {
  content: '\0045';
}

.social-icon-square-youtube::before {
  content: '\0046';
}

.social-icon-circle-facebook::before {
  content: '\0047';
}

.social-icon-circle-google-plus::before {
  content: '\0048';
}

.social-icon-circle-linkedin::before {
  content: '\0049';
}

.social-icon-circle-pinterest::before {
  content: '\004a';
}

.social-icon-circle-twitter::before {
  content: '\004b';
}

.social-icon-circle-youtube::before {
  content: '\004c';
}

.social-icon-rounded-facebook::before {
  content: '\004d';
}

.social-icon-rounded-google-plus::before {
  content: '\004e';
}

.social-icon-rounded-linkedin::before {
  content: '\004f';
}

.social-icon-rounded-pinterest::before {
  content: '\0050';
}

.social-icon-rounded-twitter::before {
  content: '\0051';
}

.social-icon-rounded-youtube::before {
  content: '\0052';
}

[class^="social-icon-circle-"]::before, [class*=" social-icon-circle-"]::before {
  border-radius: 100%;
}

[class^="social-icon-rounded-"]::before, [class*=" social-icon-rounded-"]::before {
  border-radius: 15%;
}

@font-face {
  font-family: 'real-estate-icons';
  src: url("../fonts/real-estate-icons.eot?wmpjw2");
  src: url("../fonts/real-estate-icons.eot?wmpjw2#iefix") format("embedded-opentype"), url("../fonts/real-estate-icons.ttf?wmpjw2") format("truetype"), url("../fonts/real-estate-icons.woff?wmpjw2") format("woff"), url("../fonts/real-estate-icons.svg?wmpjw2#real-estate-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="real-estate-"], [class*=" real-estate-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'real-estate-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.real-estate-icon-bath:before {
  content: "\e900";
  color: #808184;
}

.real-estate-icon-bed:before {
  content: "\e901";
  color: #808184;
}

.real-estate-icon-car:before {
  content: "\e902";
  color: #808184;
}

.btn {
  padding: 10px;
}

.btn-info {
  border-radius: 0;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  border: 1px solid #4a6693;
  color: #4a6693;
  text-shadow: none;
}

.btn-primary {
  border-radius: 0;
  background-color: #4a6693;
  background-image: none;
  box-shadow: none;
  border: none;
  color: white !important;
  text-shadow: none;
}

.btn-info:hover {
  border-color: #808184;
  background-color: transparent;
  color: #808184;
}

.btn-primary:hover {
  background-color: #808184;
}

.btn-lg {
  font-family: "Cinzel", serif;
  padding: 12px 20px;
}

/* Large desktop */
/* Desktop */
/* Mobile to Portrait tablet to landscape and desktop */
@media (max-width: 991px) {
  h1 {
    margin: 0 0 20px 0;
    font-size: 40px;
  }
  h2 {
    margin: 20px 0;
    font-size: 30px;
  }
  h3 {
    margin: 20px 0;
    font-size: 26px;
  }
  h4 {
    margin: 20px 0;
    font-size: 20px;
  }
  h5 {
    margin: 20px 0;
    font-size: 20px;
  }
  h6 {
    margin: 20px 0 5px 0;
    font-size: 20px;
  }
  p.lead {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .grecaptcha-badge {
    float: none;
  }
  .g-recaptcha.submit-btn {
    display: block;
    width: 100%;
    margin-top: 10px;
  }
}

/*=========================================================================*/
/* [Header Template Styles] */
.header {
  width: 100%;
  padding: 50px 0;
  text-align: center;
}

.header .h-logo img {
  max-width: 215px;
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .header {
    padding: 30px 20px;
  }
}

/*=========================================================================*/
/* [Navigation Template Styles] */
.navbar-default {
  margin: 50px 0 0 0;
  min-height: 10px;
  border: none;
  box-shadow: none;
  background-image: none;
  background-color: transparent;
}

.navbar-default .navbar-collapse {
  padding: 0;
}

.navbar-default .navbar-nav {
  float: none;
  display: inline-block;
}

.navbar-default .navbar-nav > li {
  margin: 0 40px;
}

.navbar-default .navbar-nav > li:first-child {
  margin-left: 0;
}

.navbar-default .navbar-nav > li:last-child {
  margin-right: 0;
}

.navbar-default .navbar-nav > li > a {
  padding: 0;
  text-transform: uppercase;
  font-size: 13px;
  text-shadow: none;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  color: #4a6693;
  text-shadow: none;
}

.navbar-default .navbar-toggle {
  width: 100%;
  margin: 0;
  border-radius: 0;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #4a6693;
  color: white;
}

.dropdown-menu {
  left: 50%;
  right: auto;
  transform: translate(-50%, 0);
  width: 160px;
  text-align: center;
}

.dropdown-menu > li > a {
  text-align: center;
  white-space: normal;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  background-image: none;
  background-color: #4a6693;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: transparent;
  background-image: none;
  color: #4a6693;
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .navbar-default {
    margin: 30px 0 0 0;
  }
  .navbar-default .navbar-nav {
    float: none;
    display: block;
    margin: 0;
  }
  .navbar-default .navbar-nav > li {
    margin: 0;
  }
  .navbar-default .navbar-nav > li > a {
    padding: 10px 15px;
    border-bottom: 1px solid rgba(128, 129, 132, 0.2);
    font-size: 14px;
  }
  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border: none;
  }
  .navbar .navbar-nav .open .dropdown-menu > .active > a, .navbar .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:focus {
    background-image: none;
    background-color: #4a6693;
    text-shadow: none;
  }
  .navbar-nav .open .dropdown-menu {
    border-bottom: 1px solid rgba(128, 129, 132, 0.2);
  }
  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 10px;
  }
}

/*=========================================================================*/
/* [Page Template Styles] */
.subpage-banner {
  width: 100%;
  padding: 0 70px;
}

.page {
  width: 100%;
  padding-top: 70px;
}

.page.page-wide {
  padding: 35px 70px 0;
}

.testimonial {
  width: 100%;
  padding: 60px;
  margin-top: 80px;
  background-color: #4a6693;
  color: white;
}

.testimonial .details {
  width: 100%;
  font-size: 17px;
  font-style: italic;
  line-height: 1.6em;
}

.testimonial .author {
  width: 100%;
  margin-top: 30px;
  font-size: 25px;
  font-family: "Cinzel", serif;
}

.testimonial-slider .slick-slide::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
}

.testimonial-slider .slick-slide img {
  position: relative;
  z-index: -1;
}

.testimonial-slider .slick-slide .testimonial {
  position: absolute;
  margin: 0;
  padding: 0;
  top: 50%;
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
  background-color: transparent;
}

.homepage-instructions {
  width: 100%;
  text-align: center;
  height: 70px;
  position: relative;
  opactiy: 0.5;
  color: #4a6693;
  font-size: 30px;
}

.homepage-instructions .glyphicon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page-homepage {
  width: 100%;
  display: flex;
}

.page-homepage .content {
  flex-basis: 58%;
  padding: 140px 70px;
  background-color: #4a6693;
  color: white;
}

.page-homepage .content h1 {
  color: #231f20;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  margin: 0 0 10px 0;
}

.page-homepage .feature-image {
  flex-basis: 42%;
}

.form-styled .form-control {
  background-color: rgba(128, 129, 132, 0.1);
}

.form-styled .control-label {
  font-weight: normal;
}

.listing {
  display: block;
  width: 100%;
  padding: 0 0 45px 0;
  margin: 0 0 45px 0;
  border-bottom: 1px solid rgba(128, 129, 132, 0.15);
  text-align: center;
  background-color: white;
  color: #808184;
}

.listing:hover, .listing:focus {
  box-shadow: 4px 4px 10px 3px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.listing.row {
  text-align: left;
  border-bottom: none;
  padding: 0;
  margin: 0 0 55px 0;
}

.listing.row .properties {
  padding: 36px 0 0;
}

.listing.row:hover, .listing.row:focus {
  box-shadow: none;
  text-decoration: none;
}

.listing p {
  margin-bottom: 40px;
}

.properties {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  vertical-align: middle;
}

.properties.properties-left {
  justify-content: initial;
}

.properties.properties-right {
  justify-content: flex-end;
}

.properties.properties-left > div,
.properties.properties-right > div {
  padding: 0 1.5rem;
}

.properties.properties-left > div:first-of-type {
  padding: 0 1.5rem 0 0;
}

.properties.properties-right > div:last-of-type {
  padding: 0 0 0 1.5rem;
}

.properties .icon {
  padding-left: 10px;
  font-size: 16px;
}

.properties .real-estate-icon-bath {
  font-size: 17px;
}

.properties .real-estate-icon-car {
  font-size: 12px;
}

.home-profile-title .properties {
  margin-top: 20px;
}

.table-home-properties tr td:nth-child(even) {
  color: #4a6693;
}

.listing-actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.listing-actions .btn {
  display: block;
  flex-basis: 45%;
  white-space: normal;
  font-size: 12px;
}

.panel-links {
  margin-bottom: 70px;
}

.panel-links a h4 {
  position: relative;
  padding-left: 2rem;
}

.panel-links a h4::before {
  content: '\003e';
  position: absolute;
  left: 0;
}

#sectionAccordion .panel {
  box-shadow: none;
  margin: 0;
  border: none;
}

#sectionAccordion .panel h4 {
  margin: 0 0 2.625rem;
}

.m-b-3 {
  margin-bottom: 3rem;
}

.home-design-gallery p {
  font-size: 16px;
  margin: 1rem 0 2rem;
}

.home-design-gallery .gallery-image {
  cursor: pointer;
}

.home-design-gallery .gallery-image-main {
  cursor: default;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .subpage-banner {
    padding: 0 20px;
  }
  .testimonial {
    padding: 40px;
    margin-top: 50px;
  }
  .testimonial .details {
    font-size: 14px;
    line-height: 1.6em;
  }
  .testimonial .author {
    font-size: 20px;
  }
  .page-homepage .content {
    flex-basis: 50%;
    padding: 70px;
  }
  .page-homepage .feature-image {
    flex-basis: 50%;
  }
  .listing-actions {
    margin-bottom: 20px;
  }
  .home-profile-title .properties {
    margin: 0 0 20px 0;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .page.page-wide {
    padding: 35px 15px 0;
  }
  .subpage-banner {
    padding: 0 20px;
  }
  .page {
    width: 100%;
    padding: 30px 20px 0 20px;
  }
  .testimonial {
    padding: 40px;
    margin-top: 30px;
  }
  .testimonial .details {
    font-size: 14px;
    line-height: 1.6em;
  }
  .testimonial .author {
    font-size: 20px;
  }
  .testimonial-slider .slickSlider {
    margin-bottom: 0;
  }
  .testimonial-slider .slickSlider .slick-dots, .testimonial-slider .slickSlider .testimonial {
    display: none;
  }
  .page-homepage {
    display: block;
  }
  .page-homepage .content {
    width: 100%;
    padding: 70px;
  }
  .page-homepage .feature-image {
    display: none;
  }
  .listing-actions {
    margin-bottom: 30px;
  }
  .table-home-properties {
    margin-top: 30px;
  }
  .listing-plan {
    display: block;
    margin-top: 30px;
  }
  .home-profile-title .properties {
    margin: 0px 0 20px 0;
  }
  .listing p {
    margin: 0 0 20px;
  }
  .listing.row .properties {
    padding: 0 0 14px;
  }
  .properties.properties-right {
    justify-content: initial;
  }
  .properties.properties-right > div:last-of-type {
    padding: 0 1.5rem;
  }
  .properties.properties-right > div:first-of-type {
    padding: 0 1.5rem 0 0;
  }
}

/*=========================================================================*/
/* [Footer Template Styles] */
.footer {
  width: 100%;
  padding: 95px 0 20px 0;
  text-align: center;
  font-size: 12px;
}

.footer a {
  color: #808184;
}

.footer a:hover {
  color: #4a6693;
  text-decoration: none;
}

.footer ul {
  display: inline-block;
  margin: 90px 0;
  padding: 0;
  list-style-type: none;
}

.footer ul > li {
  display: inline-block;
  margin: 0 20px;
  vertical-align: middle;
}

.footer ul > li > a {
  text-transform: uppercase;
}

.footer .f-logo img {
  max-width: 154px;
}

.footer .social {
  color: #4a6693;
  font-size: 24px;
}

.footer .footnotes {
  color: rgba(128, 129, 132, 0.6);
}

.footer .footnotes a {
  color: rgba(128, 129, 132, 0.6);
}

.footer .btn-primary {
  margin-bottom: 50px;
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .footer {
    padding: 60px 0 20px 0;
  }
  .footer ul {
    margin: 60px 0;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .footer {
    padding: 50px 0 20px 0;
    font-size: 13px;
  }
  .footer ul {
    margin: 40px 0;
  }
  .footer ul > li {
    margin: 5px 0;
    display: block;
  }
}

/*=========================================================================*/
/* [Bootstrap Row Clear Fix Styles] */
#media-query-detector {
  display: none;
  width: 0px;
}

@media (min-width: 768px) {
  #media-query-detector {
    width: 768px;
  }
}

@media (min-width: 992px) {
  #media-query-detector {
    width: 992px;
  }
}

@media (min-width: 1200px) {
  #media-query-detector {
    width: 1200px;
  }
}

.row-fix-clear-both {
  clear: both;
}

/*=========================================================================*/
/* [Table as row override Styles] */
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

/* col-lg */
@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

/* col-md */
@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

/* col-sm */
@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

/* col-xs */
table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}

/*=========================================================================*/
/* [Slider Styles] */
/* [Slick Slider Styles] */
.slickSlider img {
  display: none;
}

.slickSlider.slick-initialized img {
  display: block;
}

.slickSlider .slick-prev {
  left: 10px;
  z-index: 100;
  opacity: 0;
}

.slickSlider .slick-next {
  right: 10px;
  z-index: 100;
  opacity: 0;
}

.slickSlider:hover .slick-prev,
.slickSlider:hover .slick-next {
  opacity: 0.75;
  opacity: 0.75;
}

.slick-dots li button:before,
.slick-dots li.slick-active button:before {
  color: #4a6693;
}

.slick-dots li button:before {
  width: 20px;
  height: 20px;
  font-size: 12px;
}

.slider {
  width: calc(100% - 140px);
  height: calc(100vh - 325px);
  margin-left: 70px;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .slider {
    width: calc(100% - 40px);
    height: calc(100vh - 325px);
    margin-left: 20px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .slider {
    width: calc(100% - 40px);
    height: calc(100vh - 280px);
    margin-left: 20px;
  }
}

/* Landscape phones and down */
